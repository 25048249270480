.Label {
    color: white;
    margin: 0;
}

.List {
    padding: 0;
    margin: auto;
}

.ListItem {
    list-style: none;
    color: white;
}
