.App {
  text-align: center;
}

.AppHeader {
  background-color: hsl(33, 72%, 26%);
  max-height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.nope {
    text-decoration: line-through;
    color: hsl(33, 73%, 40%);
}
