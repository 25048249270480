.Container {
    cursor: pointer;
    height: 90vw;
    width: 90vw;
    max-height: 500px;
    max-width: 500px;
    background-color: hsl(33, 72%, 70%);
    border-radius: 50%;
    margin: 5vh auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Button {
    height: 4.5rem;
    width: 4.5rem;
    border-style: solid;
    border-width: 1.5rem;
    border-color: hsl(18, 72%, 70%);
    border-radius: 50%;
    /* padding: 10px; */
    background: hsl(18, 72%, 65%);
}

.Container:hover > .Button {
    background: hsl(33, 72%, 60%);
    border-color: hsl(33, 72%, 65%);
}

.loader {
  margin: 100px auto;
  font-size: 25px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load5 1.1s infinite ease;
  animation: load5 1.1s infinite ease;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
@-webkit-keyframes load5 {

  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em hsla(18, 72%, 65%, 1), 1.8em -1.8em 0 0em hsla(18, 72%, 65%, 0.2), 2.5em 0em 0 0em hsla(18, 72%, 65%, 0.2), 1.75em 1.75em 0 0em hsla(18, 72%, 65%, 0.2), 0em 2.5em 0 0em hsla(18, 72%, 65%, 0.2), -1.8em 1.8em 0 0em hsla(18, 72%, 65%, 0.2), -2.6em 0em 0 0em hsla(18, 72%, 65%, 0.5), -1.8em -1.8em 0 0em hsla(18, 72%, 65%, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em hsla(18, 72%, 65%, 0.7), 1.8em -1.8em 0 0em hsla(18, 72%, 65%, 1), 2.5em 0em 0 0em hsla(18, 72%, 65%, 0.2), 1.75em 1.75em 0 0em hsla(18, 72%, 65%, 0.2), 0em 2.5em 0 0em hsla(18, 72%, 65%, 0.2), -1.8em 1.8em 0 0em hsla(18, 72%, 65%, 0.2), -2.6em 0em 0 0em hsla(18, 72%, 65%, 0.2), -1.8em -1.8em 0 0em hsla(18, 72%, 65%, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em hsla(18, 72%, 65%, 0.5), 1.8em -1.8em 0 0em hsla(18, 72%, 65%, 0.7), 2.5em 0em 0 0em hsla(18, 72%, 65%, 1), 1.75em 1.75em 0 0em hsla(18, 72%, 65%, 0.2), 0em 2.5em 0 0em hsla(18, 72%, 65%, 0.2), -1.8em 1.8em 0 0em hsla(18, 72%, 65%, 0.2), -2.6em 0em 0 0em hsla(18, 72%, 65%, 0.2), -1.8em -1.8em 0 0em hsla(18, 72%, 65%, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em hsla(18, 72%, 65%, 0.2), 1.8em -1.8em 0 0em hsla(18, 72%, 65%, 0.5), 2.5em 0em 0 0em hsla(18, 72%, 65%, 0.7), 1.75em 1.75em 0 0em hsla(18, 72%, 65%, 1), 0em 2.5em 0 0em hsla(18, 72%, 65%, 0.2), -1.8em 1.8em 0 0em hsla(18, 72%, 65%, 0.2), -2.6em 0em 0 0em hsla(18, 72%, 65%, 0.2), -1.8em -1.8em 0 0em hsla(18, 72%, 65%, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em hsla(18, 72%, 65%, 0.2), 1.8em -1.8em 0 0em hsla(18, 72%, 65%, 0.2), 2.5em 0em 0 0em hsla(18, 72%, 65%, 0.5), 1.75em 1.75em 0 0em hsla(18, 72%, 65%, 0.7), 0em 2.5em 0 0em hsla(18, 72%, 65%, 1), -1.8em 1.8em 0 0em hsla(18, 72%, 65%, 0.2), -2.6em 0em 0 0em hsla(18, 72%, 65%, 0.2), -1.8em -1.8em 0 0em hsla(18, 72%, 65%, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em hsla(18, 72%, 65%, 0.2), 1.8em -1.8em 0 0em hsla(18, 72%, 65%, 0.2), 2.5em 0em 0 0em hsla(18, 72%, 65%, 0.2), 1.75em 1.75em 0 0em hsla(18, 72%, 65%, 0.5), 0em 2.5em 0 0em hsla(18, 72%, 65%, 0.7), -1.8em 1.8em 0 0em hsla(18, 72%, 65%, 1), -2.6em 0em 0 0em hsla(18, 72%, 65%, 0.2), -1.8em -1.8em 0 0em hsla(18, 72%, 65%, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em hsla(18, 72%, 65%, 0.2), 1.8em -1.8em 0 0em hsla(18, 72%, 65%, 0.2), 2.5em 0em 0 0em hsla(18, 72%, 65%, 0.2), 1.75em 1.75em 0 0em hsla(18, 72%, 65%, 0.2), 0em 2.5em 0 0em hsla(18, 72%, 65%, 0.5), -1.8em 1.8em 0 0em hsla(18, 72%, 65%, 0.7), -2.6em 0em 0 0em hsla(18, 72%, 65%, 1), -1.8em -1.8em 0 0em hsla(18, 72%, 65%, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em hsla(18, 72%, 65%, 0.2), 1.8em -1.8em 0 0em hsla(18, 72%, 65%, 0.2), 2.5em 0em 0 0em hsla(18, 72%, 65%, 0.2), 1.75em 1.75em 0 0em hsla(18, 72%, 65%, 0.2), 0em 2.5em 0 0em hsla(18, 72%, 65%, 0.2), -1.8em 1.8em 0 0em hsla(18, 72%, 65%, 0.5), -2.6em 0em 0 0em hsla(18, 72%, 65%, 0.7), -1.8em -1.8em 0 0em hsla(18, 72%, 65%, 1);
  }
}
@keyframes load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em hsla(18, 72%, 65%, 1), 1.8em -1.8em 0 0em hsla(18, 72%, 65%, 0.2), 2.5em 0em 0 0em hsla(18, 72%, 65%, 0.2), 1.75em 1.75em 0 0em hsla(18, 72%, 65%, 0.2), 0em 2.5em 0 0em hsla(18, 72%, 65%, 0.2), -1.8em 1.8em 0 0em hsla(18, 72%, 65%, 0.2), -2.6em 0em 0 0em hsla(18, 72%, 65%, 0.5), -1.8em -1.8em 0 0em hsla(18, 72%, 65%, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em hsla(18, 72%, 65%, 0.7), 1.8em -1.8em 0 0em hsla(18, 72%, 65%, 1), 2.5em 0em 0 0em hsla(18, 72%, 65%, 0.2), 1.75em 1.75em 0 0em hsla(18, 72%, 65%, 0.2), 0em 2.5em 0 0em hsla(18, 72%, 65%, 0.2), -1.8em 1.8em 0 0em hsla(18, 72%, 65%, 0.2), -2.6em 0em 0 0em hsla(18, 72%, 65%, 0.2), -1.8em -1.8em 0 0em hsla(18, 72%, 65%, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em hsla(18, 72%, 65%, 0.5), 1.8em -1.8em 0 0em hsla(18, 72%, 65%, 0.7), 2.5em 0em 0 0em hsla(18, 72%, 65%, 1), 1.75em 1.75em 0 0em hsla(18, 72%, 65%, 0.2), 0em 2.5em 0 0em hsla(18, 72%, 65%, 0.2), -1.8em 1.8em 0 0em hsla(18, 72%, 65%, 0.2), -2.6em 0em 0 0em hsla(18, 72%, 65%, 0.2), -1.8em -1.8em 0 0em hsla(18, 72%, 65%, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em hsla(18, 72%, 65%, 0.2), 1.8em -1.8em 0 0em hsla(18, 72%, 65%, 0.5), 2.5em 0em 0 0em hsla(18, 72%, 65%, 0.7), 1.75em 1.75em 0 0em hsla(18, 72%, 65%, 1), 0em 2.5em 0 0em hsla(18, 72%, 65%, 0.2), -1.8em 1.8em 0 0em hsla(18, 72%, 65%, 0.2), -2.6em 0em 0 0em hsla(18, 72%, 65%, 0.2), -1.8em -1.8em 0 0em hsla(18, 72%, 65%, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em hsla(18, 72%, 65%, 0.2), 1.8em -1.8em 0 0em hsla(18, 72%, 65%, 0.2), 2.5em 0em 0 0em hsla(18, 72%, 65%, 0.5), 1.75em 1.75em 0 0em hsla(18, 72%, 65%, 0.7), 0em 2.5em 0 0em hsla(18, 72%, 65%, 1), -1.8em 1.8em 0 0em hsla(18, 72%, 65%, 0.2), -2.6em 0em 0 0em hsla(18, 72%, 65%, 0.2), -1.8em -1.8em 0 0em hsla(18, 72%, 65%, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em hsla(18, 72%, 65%, 0.2), 1.8em -1.8em 0 0em hsla(18, 72%, 65%, 0.2), 2.5em 0em 0 0em hsla(18, 72%, 65%, 0.2), 1.75em 1.75em 0 0em hsla(18, 72%, 65%, 0.5), 0em 2.5em 0 0em hsla(18, 72%, 65%, 0.7), -1.8em 1.8em 0 0em hsla(18, 72%, 65%, 1), -2.6em 0em 0 0em hsla(18, 72%, 65%, 0.2), -1.8em -1.8em 0 0em hsla(18, 72%, 65%, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em hsla(18, 72%, 65%, 0.2), 1.8em -1.8em 0 0em hsla(18, 72%, 65%, 0.2), 2.5em 0em 0 0em hsla(18, 72%, 65%, 0.2), 1.75em 1.75em 0 0em hsla(18, 72%, 65%, 0.2), 0em 2.5em 0 0em hsla(18, 72%, 65%, 0.5), -1.8em 1.8em 0 0em hsla(18, 72%, 65%, 0.7), -2.6em 0em 0 0em hsla(18, 72%, 65%, 1), -1.8em -1.8em 0 0em hsla(18, 72%, 65%, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em hsla(18, 72%, 65%, 0.2), 1.8em -1.8em 0 0em hsla(18, 72%, 65%, 0.2), 2.5em 0em 0 0em hsla(18, 72%, 65%, 0.2), 1.75em 1.75em 0 0em hsla(18, 72%, 65%, 0.2), 0em 2.5em 0 0em hsla(18, 72%, 65%, 0.2), -1.8em 1.8em 0 0em hsla(18, 72%, 65%, 0.5), -2.6em 0em 0 0em hsla(18, 72%, 65%, 0.7), -1.8em -1.8em 0 0em hsla(18, 72%, 65%, 1);
  }
}
